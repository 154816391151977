import "./erreur.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse} from "@fortawesome/free-solid-svg-icons";
function Erreur() {
  return (
    <div className="pageerreur">
      <div className="licorne">

         <div className='phrase'>
            Oups <br/>
            Pink invisible Unicorn should remain invisible
        </div>
        <div > <a className="boutonretour" href = "/"><FontAwesomeIcon icon={faHouse} style={{ color: "#205883" }} size="2x" /></a> </div>
      </div>

    </div>
  );
}
export default Erreur;