import React from 'react';
import './mentionlegale.css';

const Mentions = () => {
  return (
    <div className="mentions-container">
      <h1 className="mentions-title">Mentions Légales</h1>
      <h2 className="mentions-subtitle">Édition</h2>
      <p className="mentions-paragraph">
        Le site hegeoma.fr est édité par :
      </p>
      <p className="mentions-paragraph">
        Hegeoma SAS<br />
        6, rue de Sault<br />
        38000 Grenoble
      </p>
      <p className="mentions-paragraph">
        Vous pouvez nous contacter sur notre page de contact.
      </p>
      <h2 className="mentions-subtitle">Publication</h2>
      <p className="mentions-paragraph">
        Directeur de la publication: Raphaël Jimenez
      </p>
      <h2 className="mentions-subtitle">Hébergement</h2>
      <p className="mentions-paragraph">
        Le site hegeoma.fr est hébergé par :
      </p>
      <p className="mentions-paragraph">
        OVH<br />
        2 rue Kellermann<br />
        59100 Roubaix – France.
      </p>
      <h2 className="mentions-subtitle">Propriété intellectuelle</h2>
      <p className="mentions-paragraph">
        L’ensemble de ce site relève des législations françaises et internationales sur le droit d’auteur et la propriété intellectuelle. Tous les droits de reproduction sont réservés, y compris pour les documents iconographiques et photographiques.
      </p>
      <p className="mentions-paragraph">
        Les textes et illustrations figurant sur le site www.hegeoma.fr sont soumis aux droits d’auteur et ne peuvent être reproduits, distribués ou modifiés sans autorisation expresse.
      </p>
      <p className="mentions-paragraph">
        L’ensemble des éléments de ce site, en particulier sa structure, sa présentation, ses textes, ses illustrations, ses photographies, ses programmes et ses images, ne peut faire l’objet d’une quelconque modification, altération, reproduction partielle ou totale et, plus généralement, d’aucune exploitation, sans accord écrit préalable.
      </p>
      <p className="mentions-paragraph">
        Hegeoma n’accorde pas de licence pour utiliser ou reproduire tout et/ou partie du contenu de ce site autrement que par téléchargement et impression, pour un usage purement personnel et non commercial. En conséquence, toute autre utilisation et toute autre communication, reproduction et/ou transmission partielle et/ou totale, sont strictement interdites.
      </p>
      <p className="mentions-paragraph">
        Hegeoma et son logo sont la propriété de la société Hegeoma SAS.
      </p>
      <p className="mentions-paragraph">
        En cas de non-respect d’une quelconque de ces obligations, Hegeoma se réserve le droit de poursuivre par tout moyen, le responsable de l’infraction, quelle qu’en soit la nature, et de demander les condamnations ainsi que les dommages et intérêts appropriés.
      </p>
      <h2 className="mentions-subtitle">Crédit photos et informations</h2>
      <p className="mentions-paragraph">
        © Hegeoma, © pixabay.com, © pexels.com
      </p>
      <h2 className="mentions-subtitle">Données personnelles</h2>
      <p className="mentions-paragraph">
        Dans une logique de respect de la vie privée de ses utilisateurs, Hegeoma s’engage à ce que la collecte et le traitement d’informations personnelles, effectués au sein du présent site, soient effectués conformément à la loi « informatique et libertés » du 6 janvier 1978 modifiée.
      </p>
      <p className="mentions-paragraph">
        A ce titre, le site hegeoma.fr fait l’objet d’une déclaration à la CNIL n°1984118.
      </p>
      <p className="mentions-paragraph">
        Conformément à l’article 34 de la loi « Informatique et Libertés », l’utilisateur a un droit d’opposition, d’accès et de rectification sur les données personnelles le concernant, que vous pouvez exercer sur simple demande sur notre page de contact. Vous pouvez également, pour des motifs légitimes, vous opposer au traitement des données vous concernant.
      </p>
      <h2 className="mentions-subtitle">Liens Hypertextes</h2>
      <p className="mentions-paragraph">
        Certains liens sur ce site peuvent vous mener vers d’autres sites. Aucune garantie d’aucune sorte ne peut être apportée par Hegeoma sur le contenu ou l’exactitude des informations figurant sur ces autres sites. Hegeoma décline toute responsabilité de quelque nature que ce soit pour ces autres sites ou les informations qu’ils contiennent. Nous vous invitons à contrôler les mentions légales de ces différents sites que vous visiterez.
      </p>
      <p className="mentions-paragraph">
        Hegeoma s’efforce de mettre à jour le site, mais n’offre pas de garanties quant à l’exactitude des informations et/ou leur caractère exhaustif et, ne saurait donc être responsable à quelque titre que cela soit d’éventuels dommages directs et indirects pouvant découler de l’accès à ce site et/ou de l’utilisation des informations qu’il contient.
      </p>
    </div>
  );
};

export default Mentions;
