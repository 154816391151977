import React, { useEffect } from 'react';

const Scrollup = ({ children }) => {
  useEffect(() => {
    window.scrollTo(0, 0); // Défile en haut de la page lors du montage du composant
  }, []);

  return <div>{children}</div>;
};

export default Scrollup;
