import React from 'react';
import './unicorn.css';


const Unicorn = () => {


  return (
    <div className="logo-page">
      <div className='licorne'>
            Bonjour
      </div>
    </div>
  );
};

export default Unicorn;
