import React from 'react';

import logo from './../logo.png';

const LogoPage = () => {

  return (
    <div >
      <img src={logo} alt="Hegeoma Logo" className="logo-image" />
    </div>
  );
};

export default LogoPage;
