import React, { useState } from 'react';
import './home.css';
import Typewriter from './Typewriter.js';
import DivAnimee from './divAnimee/divAnimee.js';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Carousel from '../home/caroussel/carousel.js';
import ServiceList from './tableau/tableau.js';
import Blog from './blog/blog.js';
import BackToTop from './Backtotop/index.js';
import { faChevronDown, faCheck } from "@fortawesome/free-solid-svg-icons";
import Map from '../map/map.js';

const Home = () => {
  const image1 = require('../../images/pexels-gabriel-freytez-110599-341523.jpg');
  const image2 = require('../../images/pexels-pixabay-4158.jpg');
  const image3 = require('../../images/pexels-jeshoots-218863.jpg');
  const image4 = require('../../images/pexels-donatellotrisolino-1336855.jpg');
  const image5 = require('../../images/pexels-michaela-87369-295826 (1).jpg');
  const image6 = require('../../images/pexels-karolina-grabowska-7679634.jpg');
  const image7 = require('../../images/pexels-jessbaileydesign-788946 (1).jpg');

  const carouselItems = [
    {
      image: image1,
      alt: 'Slide 1',
      caption: 'Mobilité et gestion de flotte (MDM)',
      url: '/service/mobilité-et-gestion-de-flotte' // Remplacez par l'URL de la page correspondante
    },
    {
      image: image2,
      alt: 'Slide 2',
      caption: 'Intégration',
      url: '/service/integration' // Remplacez par l'URL de la page correspondante
    },
    {
      image: image3,
      alt: 'Securité',
      caption: 'Sécurité',
      url: '/service/securite' // Remplacez par l'URL de la page correspondante
    },
    {
      image: image4,
      alt: 'Slide 4',
      caption: 'Maintenance',
      url: '/service/maintenance' // Remplacez par l'URL de la page correspondante
    },
    {
      image: image5,
      alt: 'Slide 5',
      caption: 'Transfert de compétences',
      url: '/service/transfert-de-compétences' // Remplacez par l'URL de la page correspondante
    },
    {
      image: image6,
      alt: 'Slide 6',
      caption: 'Support',
      url: '/service/support' // Remplacez par l'URL de la page correspondante
    },
    {
      image: image7,
      alt: 'Slide 7',
      caption: 'Audit et conseil',
      url: '/service/audit-et-conseil' // Remplacez par l'URL de la page correspondante
    },
  ];

  return (
    <div>
      <div className='page1 fade-in' id="home">
        <div className='partie1'>
          <div className='surtitre'>Bienvenue chez</div>
          <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <Typewriter text="HEGEOMA" />
          </div>
        </div>
      </div>
      <Carousel items={carouselItems} />

    
   <BackToTop />
    </div>
  );
};

export default Home;
