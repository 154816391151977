import React, { useEffect, useRef, useState } from 'react';
import "./services.css";
import BackToTop from '../home/Backtotop';
import integrationImage from "../../images/pexels-morningtrain-18105.jpg";
import supportImage from "../../images/pexels-tirachard-kumtanom-112571-733857.jpg";
import formationImage from "../../images/pexels-goumbik-1420709.jpg";
import mobiliteImage from "../../images/pexels-japy-1069798.jpg";
import maintenanceImage from "../../images/pexels-jeshoots-218863.jpg";
import auditImage from "../../images/pexels-pixabay-531844.jpg";
import { Link } from 'react-router-dom';

function Services() {
    const serviceRefs = useRef([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('visible');
                    } else {
                        entry.target.classList.remove('visible');
                    }
                });
            },
            { threshold: 0.1 } // Ajustez le seuil selon vos besoins
        );

        serviceRefs.current.forEach((ref) => {
            if (ref) observer.observe(ref);
        });

        return () => {
            serviceRefs.current.forEach((ref) => {
                if (ref) observer.unobserve(ref);
            });
        };
    }, []);

    useEffect(() => {
        const images = [
            integrationImage,
            supportImage,
            formationImage,
            mobiliteImage,
            maintenanceImage,
            auditImage
        ];

        const loadImages = () => {
            const promises = images.map(src => {
                return new Promise((resolve, reject) => {
                    const img = new Image();
                    img.src = src;
                    img.onload = resolve;
                    img.onerror = reject;
                });
            });

            Promise.all(promises).then(() => {
                setLoading(false);
            });
        };

        loadImages();
    }, []);

    const serviceDetails = [
        {
            title: "Integration",
            description: "Hegeoma prépare, installe et déploie vos Mac, iPhone ou iPad, Chromebook, PC, NAS Synology ou tablettes Android dans votre infrastructure actuelle ou à venir. Nous intégrons également en environnement hétérogène. Hegeoma peut travailler au forfait et sait s’adapter à votre budget.",
            backgroundImage: integrationImage,
            color: "white",
            backgroundcolor: "black"
        },
        {
            title: "Support",
            description: "Hegeoma peut prendre en charge le support de tout ou partie de votre parc informatique, y compris vos iPhone, iPad ou Mac, Chromebook ou tablettes Android en situation de mobilité.",
            backgroundImage: supportImage,
            color: "black",
            backgroundcolor: "white"
        },
        {
            title: "Transfert de compétences",
            description: "Vous cherchez une formation sur mesure, matérielle (Mac, iPhone, iPad) ou logicielle (OS X, iOS, intégration dans un environnement Windows sous Active Directory) ? Une formation pour un logiciel donné ? Une formation certifiante ? Hegeoma vous propose ses services en inter ou intra entreprise.",
            backgroundImage: formationImage,
            color: "black",
            backgroundcolor: "white"
        },
        {
            title: "Mobilité et gestion de flotte",
            description: "Vous désirez gérer et déployer plus facilement votre flotte d’appareils avec un MDM ? Pour Mac, iPhone, iPad, PC, tablettes Android ou Chromebook ? Hegeoma vous accompagne.",
            backgroundImage: mobiliteImage,
            color: "white",
            backgroundcolor: "black"
        },
        {
            title: "Maintenance",
            description: "Vous souhaitez que quelqu’un vous conseille, assure la bonne évolution matérielle et logicielle de votre parc informatique ? Vous souhaitez faire évoluer certains Mac, iPhone, iPad, Chromebook ou PC ? Hegeoma vous propose ses services.",
            backgroundImage: maintenanceImage,
            color: "white",
            backgroundcolor: "black"
        },
        {
            title: "Audit et conseil",
            description: "Vous désirez un conseil indépendant ? Un audit indépendant effectué par un professionnel certifié Apple ? Vous souhaitez un audit de sécurité pour vos solutions Apple ? Hegeoma vous propose ses services.",
            backgroundImage: auditImage,
            color: "black",
            backgroundcolor: "white"
        }
    ];

    const formatTitleForUrl = (title) => {
        return title.replace(/\s+/g, '-').toLowerCase();
    };

    const renderServiceRows = () => {
        const rows = [];
        for (let i = 0; i < serviceDetails.length; i += 2) {
            rows.push(
                <div className="ligne" key={i}>
                    <div
                        className="service fade-in"
                        style={{ backgroundImage: `url(${serviceDetails[i].backgroundImage})` }}
                        ref={el => serviceRefs.current[i] = el}
                    >
                        <div className='grostitre' style={{ color: serviceDetails[i].color }}>{serviceDetails[i].title}</div>
                        <div className='fondtexte'>
                            <div className='titreligne'>{serviceDetails[i].title}</div>
                            <div className='descriptionligne'>{serviceDetails[i].description}</div>
                            <Link to={`/service/${formatTitleForUrl(serviceDetails[i].title)}`} className="en-savoir-plus">En savoir plus</Link>
                        </div>
                    </div>
                    {serviceDetails[i + 1] && (
                        <div
                            className="service fade-in"
                            style={{ backgroundImage: `url(${serviceDetails[i + 1].backgroundImage})` }}
                            ref={el => serviceRefs.current[i + 1] = el}
                        >
                            <div className='grostitre' style={{ color: serviceDetails[i + 1].color }}>{serviceDetails[i + 1].title}</div>
                            <div className='fondtexte'>
                                <div className='titreligne'>{serviceDetails[i + 1].title}</div>
                                <div className='descriptionligne'>{serviceDetails[i + 1].description}</div>
                                <Link to={`/service/${formatTitleForUrl(serviceDetails[i + 1].title)}`} className="en-savoir-plus">En savoir plus</Link>
                            </div>
                        </div>
                    )}
                </div>
            );
        }
        return rows;
    };

    return (
        <div className="arc-background">
            <div className="content">
                <div className="pagesservice">
                    <div className='pagesecurite fade-in'>
                        <div className='pagesecuriteinterieur'>
                            <div className='espaceheader'></div>
                            <div className='nosservicesheader'>NOS SERVICES</div>
                            <div className='divsecu'>
                                <div className='titresecurite titreligne'>Sécurité</div>
                                <div className='descriptionsecurite'>
                                    <div className='titrelignesec'>Sécurité</div>
                                    <div className='descriptionligne'>
                                        Vous souhaitez un Audit de l’installation de votre MDM ?<br/>
                                        Vous souhaitez sécuriser vos appareils mobiles ou fixes ?<br/>
                                        Vous souhaitez faire auditer votre Console Google ?<br/>
                                        Vous recherchez un antivirus avec console centrale et outils de remédiation ?<br/>
                                        Vous souhaitez mettre en place une solution de sauvegarde ?<br/>
                                        Vous souhaitez contrôler l’accès aux contenus déplacés en environnement Pro ou Education ?<br/>
                                        Vous souhaitez installer un ou plusieurs firewall(s) ?<br/>
                                        Hegeoma peut vous accompagner sur ces sujets.
                                    </div>
                                    <div><a className="en-savoir-plus" href='/service/securite'>En savoir plus</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='pagenouveauxservices'>
                        {renderServiceRows()}
                    </div>
                </div>
            </div>
            <BackToTop />
        </div>
    );
}

export default Services;
