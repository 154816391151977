import React from 'react';
import articleImage2 from '../../../images/pexels-jourdan-wee-2023410-3644098.jpg'; // L'image associée
import './articles.css';

function Article2() {
    return (
        <div className='article-page'>
            <div className='fenetrearticle'>
                <img src={articleImage2} alt="Lorem Ipsum" className='article-image' />
                <div className='article-header'>
                    <span className='article-date'>01 Octobre 2023</span>
                    <h1>Article 2</h1>
                    <span className='article-author'>Par Aristote</span>
                </div>
                <div className='textearticle'>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...
                        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat...
                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur...
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...
                        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat...
                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur...
                    </p>
                    <p>je suis une liste</p>
                    <ul>
                        <li>irure</li>
                        <li>dolor</li>
                        <li>veniam</li>
                    </ul>
                    <p>Une liste avec des numeros</p>
                    <ol>
                        <li>irure</li>
                        <li>dolor</li>
                        <li>veniam</li>
                    </ol>
                    <p>Une liste avec des numeros</p>
                    <ol>
                        <li>irure</li>
                        <li>dolor</li>
                        <li>veniam</li>
                    </ol>
                </div>
            </div>
        </div>
    );
}

export default Article2;
