import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Services from "./component/services/index.js";
import logo from './logo.svg';
import Header from './component/header/index';
import Footer from './component/footer/index';
import Home from './component/home/index';
import Mentions from './component/mentionlegales/index.js';
import Contact from './component/contact/contact.js';
import Blog from "./component/home/blog/pageblog.js";
import Erreur from "./component/erreur/index.js";
import Login from "./component/login/login.js";
import Signup from "./component/login/signup.js";
import Faq from './component/user/faq/index.js';
import { isUserAuthorized } from './component/header/statut.js';
import AdminPage from './component/tabdebordadmin/pageadmin.js';
import HegeomaRoute from './component/privateroute/hegeomaroute.js'; /*on ne peut acceder à la page que s'il on est authentifié*/
import AdminRoute from './component/privateroute/adminroute.js';
import NonConnecteRoute from './component/privateroute/nonconnecteroute.js';
import ClientRoute from './component/privateroute/clientroute.js';
import OrgRoute from './component/privateroute/orgroute.js';
import UserSchoolList from './component/user/bdd/mesecoles/ecoles.js';
import SchoolForm from './component/user/formulaire/formschool/formschool.js';
import SchoolList from './component/tabdebordadmin/listeschool.js';
import Classes from './component/user/formulaire/formschool/classes.js';
import Eleves from './component/user/formulaire/formschool/eleves.js';
import Prof from './component/user/formulaire/formschool/prof.js';
import Schoolapp from './component/user/formulaire/formschool/appschool.js';
import SearchBar from "./component/user/bdd/mesecoles/infoeleve.js";
import SearchTeachers from './component/user/bdd/mesecoles/infoprofs.js';
import EcoleInfo from './component/user/bdd/mesecoles/infoecole.js';
import Entreprises from './component/user/bdd/mesentreprises/entreprises.js';
import withAuthentication from './component/fonctions/hoc.js';
import { AuthProvider } from './component/privateroute/authcontext.js';
import Erreuracces from './component/erreur/pasacces.js';
import ReponseFormulaire from './component/hegeoma/reponseformulaire/reponseformulaire.js';
import ClientnoncoRoute from './component/privateroute/clientetnoncoroute.js';
import SchoolRoute from './component/privateroute/schoolroute.js';
import OrganismeDetailsAdmin from './component/tabdebordadmin/infosupporgadmin.js';
import OrgForm from './component/user/formulaire/formentreprise/orgform.js';
import OrganismeDetails from './component/user/bdd/mesentreprises/infosupentreprise.js';
import SchoolListt from './component/tabdebordadmin/voirplusecole.js';
import OrgList from './component/tabdebordadmin/voirplusorganisme.js';
import ListeEntreprises from './component/tabdebordadmin/entreprises.js';
import AjoutFaq from './component/tabdebordadmin/ajoutquestionfaq.js';
import Blogg from './component/blog/blog.js';
import Article1 from './component/blog/blogs/article1.js';
import Article2 from './component/blog/blogs/article2.js';
import Article3 from './component/blog/blogs/article3.js';
import LogoPage from './component/header/pagelogo/pagelogo.js';
import Unicorn from './component/header/pagelogo/unicorn.js';

import Support from './component/services/pageservices/support.js';
import Securite from './component/services/pageservices/securite.js';
import Mobilitegestion from './component/services/pageservices/mobilitegestion.js';
import Maintenance from './component/services/pageservices/maintenance.js';
import Integration from './component/services/pageservices/integration.js';
import Formation from './component/services/pageservices/formation.js';
import Auditetconseil from './component/services/pageservices/auditetconseil.js';

import Scrollup from './component/fonctions/scrollup.js';
import './App.css';

const Layout = ({ children }) => {
  return (
    <Scrollup>
    <div>
      <Header />
      <main>{children}</main>
      <Footer />
    </div>
    </Scrollup>
  );
};

const NoHeaderFooterLayout = ({ children }) => {
  return (
    <div>
      <main>{children}</main>
    </div>
  );
};

const Authenticatedfaq = withAuthentication(Faq);
const AuthenticatedSchoolForm = withAuthentication(SchoolForm);
const AuthenticatedUserSchoolList = withAuthentication(UserSchoolList);
const AuthenticatedClasses = withAuthentication(Classes);
const AuthenticatedEleves = withAuthentication(Eleves);
const AuthenticatedProf = withAuthentication(Prof);
const AuthenticatedSearchBar = withAuthentication(SearchBar);
const AuthenticatedSearchTeachers = withAuthentication(SearchTeachers);
const AuthenticatedEcoleInfo = withAuthentication(EcoleInfo);
const AuthenticatedSchoolapp = withAuthentication(Schoolapp);

const AuthenticatedReponseFormulaire  = withAuthentication(ReponseFormulaire );

const AuthenticatedAdminPage = withAuthentication(AdminPage);
const AuthenticatedSchoolList = withAuthentication(SchoolList);
const AuthenticatedOrganismeDetailsAdmin = withAuthentication(OrganismeDetailsAdmin);
const AuthenticatedSignup = withAuthentication(Signup);

const AuthenticatedOrgForm = withAuthentication(OrgForm);
const AuthenticatedEntreprises = withAuthentication(Entreprises);
const AuthenticatedOrganismeDetails = withAuthentication(OrganismeDetails);
const AuthenticatedSchoolListt = withAuthentication(SchoolListt);
const AuthenticatedOrgList = withAuthentication(OrgList);
const AuthenticatedListeEntreprises = withAuthentication(ListeEntreprises);
const AuthenticatedAjoutFaq = withAuthentication(AjoutFaq);

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <Router>
          <Routes>
            <Route element={<ClientnoncoRoute />}>
              <Route path="/" element={<Layout><Home /></Layout>} />
              <Route path="/services" element={<Layout><Services /></Layout>} />
              <Route path="/blog" element={<Layout><Blogg /></Layout>} />
              <Route path="/article/1" element={<Layout><Article1 /></Layout>} />
              <Route path="/article/2" element={<Layout><Article2 /></Layout>} />
              <Route path="/article/3" element={<Layout><Article3 /></Layout>} />

              <Route path="/service/audit-et-conseil" element={<Layout><Auditetconseil /></Layout>} />
              <Route path="/service/maintenance" element={<Layout><Maintenance /></Layout>} />
              <Route path="/service/transfert-de-compétences" element={<Layout><Formation /></Layout>} />
              <Route path="/service/mobilité-et-gestion-de-flotte" element={<Layout><Mobilitegestion /></Layout>} />
              <Route path="/service/support" element={<Layout><Support /></Layout>} />
              <Route path="/service/integration" element={<Layout><Integration /></Layout>} />
              <Route path="/service/securite" element={<Layout><Securite /></Layout>} />


              <Route path="/contact" element={<Layout><Contact /></Layout>} />
              <Route path="/unicorn" element={<Layout><Unicorn /></Layout>} />
              <Route path="/wp-content/uploads/2016/08/Logo-Hegeoma_300.png" element={<NoHeaderFooterLayout><LogoPage /></NoHeaderFooterLayout>} />
            </Route>

            <Route element={<ClientRoute />}>
              <Route path="/faq" element={<Layout><Authenticatedfaq /></Layout>} />
            </Route>

            <Route element={<SchoolRoute />}>
              <Route path="/schoolform" element={<Layout><AuthenticatedSchoolForm /></Layout>} />
              <Route path="/userschool" element={<Layout><AuthenticatedUserSchoolList /></Layout>} />
              <Route path="/classes" element={<Layout><AuthenticatedClasses /></Layout>} />
              <Route path="/eleves" element={<Layout><AuthenticatedEleves /></Layout>} />
              <Route path="/prof" element={<Layout><AuthenticatedProf /></Layout>} />
              <Route path='/infoeleve' element={<Layout><AuthenticatedSearchBar /></Layout>} />
              <Route path='/infoprof' element={<Layout><AuthenticatedSearchTeachers /></Layout>} />
              <Route path='/infoecole' element={<Layout><AuthenticatedEcoleInfo /></Layout>} />
              <Route path='/appecole' element={<Layout><AuthenticatedSchoolapp /></Layout>} />
            </Route>

            <Route element={<OrgRoute />}>
              <Route path="/orgform" element={<Layout><AuthenticatedOrgForm /></Layout>} />
              <Route path="/organismes" element={<Layout><AuthenticatedEntreprises /></Layout>} />
              <Route path="/infoorg" element={<Layout><AuthenticatedOrganismeDetails /></Layout>} />
            </Route>

            <Route element={<HegeomaRoute />}>
              <Route path="/reponse-formulaire" element={<Layout><AuthenticatedReponseFormulaire /></Layout>} />
            </Route>

            <Route element={<ClientnoncoRoute />}>
              <Route path="/signup" element={<Layout><Signup /></Layout>} />
            </Route>

            <Route element={<AdminRoute />}>
              <Route path="/admin" element={<Layout><AuthenticatedAdminPage /></Layout>} />
              <Route path="/school" element={<Layout><AuthenticatedSchoolList /></Layout>} />
              <Route path="/infoschool" element={<Layout><AuthenticatedSchoolListt /></Layout>} />
              <Route path="/infousersorg" element={<Layout><AuthenticatedOrgList /></Layout>} />
              <Route path="/entreprises" element={<Layout><AuthenticatedListeEntreprises /></Layout>} />
              <Route path="/orgdetailadmin" element={<Layout><AuthenticatedOrganismeDetailsAdmin /></Layout>} />
              <Route path="/ajoutfaq" element={<Layout><AuthenticatedAjoutFaq /></Layout>} />
            </Route>

            <Route path="/mentionslegales" element={<Layout><Mentions /></Layout>} />
            <Route path="/login" element={<Layout><Login /></Layout>} />
            <Route path="/erreur" element={<Layout><Erreuracces /></Layout>} />
            <Route path="*" element={<Layout><Erreur /></Layout>} />
          </Routes>
        </Router>
      </AuthProvider>
    </div>
  );
}

export default App;
