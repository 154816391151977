import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './blog.css';
import BackToTop from '../home/Backtotop';

import articleImage1 from '../../images/pexels-annpoan-5797939.jpg';
import articleImage2 from '../../images/pexels-jourdan-wee-2023410-3644098.jpg';
import articleImage3 from '../../images/pexels-ekaterina-bolovtsova-6192119.jpg';

const articles = [
    { id: 1, title: 'Article 1', content: 'Lorem ipsum dolor sit amet...', date: '2023-10-01', creator: 'Aristote', image: articleImage1 },
    { id: 2, title: 'Article 2', content: 'Sed ut perspiciatis unde...', date: '45 av. J.-C', creator: 'Ciceron', image: articleImage2 },
    { id: 3, title: 'Article 3', content: 'At vero eos et accusamus...', date: '45 av. J.-C.', creator: 'Ciceron', image: articleImage3 },
];

function Blogg() {
    const [searchTerm, setSearchTerm] = useState('');

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredArticles = articles.filter(article =>
        article.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        article.content.toLowerCase().includes(searchTerm.toLowerCase()) ||
        article.date.toLowerCase().includes(searchTerm.toLowerCase()) ||
        article.creator.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className='pageblogg'>
            <h1>Blog</h1>
            <input
                type="text"
                placeholder="Rechercher un article..."
                value={searchTerm}
                onChange={handleSearch}
                className='search-bar'
            />
            <ul className='article-list'>
                {filteredArticles.map(article => (
                    <li key={article.id} className='articleunite'>
                        <Link to={`/article/${article.id}`}>
                            <img src={article.image} alt={article.title} className='article-image' />
                            <h2>{article.title}</h2>
                        </Link>
                        <p className='article-content'>{article.content}</p>
                        <div className='article-meta'>
                            <span>Date: {article.date}</span>
                            <span>
                                <Link to={`/article/${article.id}`}>
                                    <button>Voir plus</button>
                                </Link>
                            </span>
                            <span>Créateur: {article.creator}</span>
                        </div>
                    </li>
                ))}
            </ul>
            <BackToTop />
        </div>
    );
}

export default Blogg;
